<template>
  <b-nav-item-dropdown
    id="dropdown-grouped"
    variant="link"
    class="dropdown-language"
    right
  >
    <template #button-content>
      <b-img
        :src="currentLocale.img"
        height="14px"
        width="22px"
        :alt="currentLocale.locale"
      />
      <span class="ml-50 text-body">{{ currentLocale.name }}</span>
    </template>
    <b-dropdown-item
      v-for="localeObj in locales"
      :key="localeObj.locale"
      @click="setLanguage(localeObj.locale)"
    >
      <b-img
        :src="localeObj.img"
        height="14px"
        width="22px"
        :alt="localeObj.locale"
      />
      <span class="ml-50">{{ localeObj.name }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  // ! Need to move this computed property to comp function once we get to Vue 3
  computed: {
    currentLocale() {
      // eslint-disable-next-line no-return-assign,vue/no-side-effects-in-computed-properties
      return this.locales.find(locale => locale.locale === this.$i18n.locale)
    },
  },
  created() {
    this.currentItem()
  },
  methods: {
    setLanguage(lang) {
      this.$i18n.locale = lang
      if (lang === 'ar') {
        this.direction = 'rtl'
        window.location.reload()
      } else {
        this.direction = 'ltr'
        window.location.reload()
      }
    },
    currentItem() {
      const $theLang = this.direction === 'rtl' ? 'ar' : 'en'
      // eslint-disable-next-line no-return-assign,vue/no-side-effects-in-computed-properties
      return this.$i18n.locale = $theLang
    },
  },
  setup() {
    const { direction } = useAppConfig()
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'ar',
        img: require('@/assets/images/flags/ar.png'),
        name: 'Arabic',
      },
    ]
    const currentDirection = computed(() => direction.value)
    /* eslint-disable global-require */
    return {
      locales,
      direction,
      currentDirection,
    }
  },
}
</script>

<style>

</style>
